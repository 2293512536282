* {
  margin: 0;
  padding: 0;
}

.image-gallery-container {
  width: 90%;
  margin-left: 5%;
}

.image-gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.image-gallery-inner-wrapper {
  margin: 3%;
}

.image-gallery-images {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
  /* padding: 3%; */
}

.image-gallery-heading-container {
  padding-top: 3%;
  padding-left: 1%;
  position: relative;
}

.image-gallery-heading {
  margin: 0%;
  position: relative;
  z-index: 1;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 200%;
  line-height: 300%;
  color: #1b1c1d;
}

.image-gallery-heading-hr {
  border: 3px solid #ffd404;
  margin: 0%;
  width: 16%;
  position: absolute;
  bottom: 26%;
  left: 1%;
}

/* FULL SCREEN CSS */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.945);
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;


}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);

}

.model img {
  width: 100%;
  max-width: 100%;
  height: auto;
  height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 4% 5% 4% 5%;
  margin: 0% auto;

}

.model.open svg {
  position: fixed;
  top: 2%;
  right: 2%;
  width: 2rem;
  height: 2rem;
  padding: 2%;
  background-color: #fff;
  color: red;
  cursor: pointer;
  z-index: 1000;
}

.image-gallery-button {
  color: rgb(235, 235, 238);
  position: absolute;
  right: 1rem;
  padding: 0.4%;
  background-color: transparent;
  border: none;
  top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross-icon {
  font-size: 190%;
}



/* MEDIA QUERIES */
@media screen and (max-width: 767px) {
  .image-gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .model img {
    width: 100%;
    max-width: 100%;
    height: auto;
    height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 4% 5% 4% 5%;
    margin: 0% auto;

  }

  .model img {
    width: 100%;
    max-width: 100%;
    height: 40vh;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 4% 5% 4% 5%;
    margin: 0% auto;

  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .image-gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}