@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.trainer-mentor-container {
    margin-left: 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.cards-card {
    width: 90%;
    display: grid;
    justify-content: center;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 5%;
    margin-bottom: 60px;
  }

.card-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-img-container img {
    height: 172px;
    width: 172px;
    border-radius: 100%;
}

.card-heading {
    color: #D9281F;
    margin-top: 24px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-para {
    margin-top: 8px;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .trainer-mentor-container {
        margin-left: 5%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .trainer-mentor-container {
        margin-left: 5.5%;
        margin-right: 2.5%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}