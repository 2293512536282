@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.blog-page-img-container{
    background-image: url('../../assets/Blogs/blogImg.webp');
    height: 15rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.blog-page-img-container-text{
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: #fff;
    font-size: 240%;
    width: 100%;
    height: 100%;
    margin-bottom: 0%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.137);
    padding-bottom: 2%;
}


p{
    margin-bottom: 0%;
}


.blogList-page-container{
    padding: 1.5%;
}

.blogList-page-heading{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;

}

.blogList-page-hr-tag{
    border-bottom: 1px solid rgb(189, 188, 188);
}

.bloglist-latest-blog-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 85%;
    margin-left: 7.5%;
    margin-top: 4%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.blog-list-latest-card-edit-delete-icon-container{
    width: 85%;
    margin-left: 7.5%;
    padding-top: 1%;
}


.bloglist-latest-blog-img-wrapper{
    height: 23rem;
}

.bloglist-latest-blog-img{
    width: 100%;
    height: 100%;
}

.bloglist-latest-blog-content-wrapper{
    padding: 2% 3% 2% 3%;
}

.bloglist-latest-blog-content-heading{
    font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 150%;
    margin-bottom: 2%;
  color: rgba(87, 86, 86, 1);

}

.bloglist-latest-blog-summary{
    font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  color: rgba(87, 86, 86, 1);
  font-size: 105%;
  margin-bottom: 3%;
}

.bloglist-latest-blog-date{
    font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  color: rgba(87, 86, 86, 1);
  font-size: 110%;
}

.bloglist-latest-blog-authorName{
    font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  color: rgba(87, 86, 86, 1);
  font-size: 110%;
}

.blog-list-cards-container{
    border: 1px solid red;
    display: grid;
}

.all-blog-list-container{
    width: 88%;
    margin-left: 6%;
    margin-top: 4%;
}

.all-blog-list-inner-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
 margin-bottom: 3%;

}

.all-blog-list-card{
    margin: 4%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.all-blog-list-card-img{
    width: 100%;
    height: 15rem;
}

.all-blog-list-card-title{
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    color: rgba(87, 86, 86, 1);
    font-size: 115%;
    font-weight: 600;
    width: 90%;
    margin-left: 5%;
    margin-top: 4%;
}


.all-blog-list-card-summary{
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    color: rgba(87, 86, 86, 1);
    font-size: 100%;
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
}

.all-blog-list-card-date-author-container{
    display: flex;
    justify-content: space-between;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    color: rgba(87, 86, 86, 1);
    font-weight: 600;
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
}

.latest-blog-card-read-more-btn{
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    width: 90%;
    margin-top: 3%;
    color: blue;
    text-decoration: none;
}

.blog-card-read-more-btn{
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
    padding-bottom: 4%;
    color: blue;
    text-decoration: none;
}

.blog-list-card-edit-delete-icon-container{
    width: 90%;
    margin-left: 5%;
    padding-bottom: 2%;
}

.blog-list-card-edit-icons{
    font-size: 115%;
    margin-right: 3%;
    cursor: pointer;
}

.blog-list-card-delete-icons{
    font-size: 115%;
    margin-right: 3%;
    color: rgb(236, 44, 44);
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .blog-page-img-container{
        height: 6rem;
    }

    .bloglist-latest-blog-container{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        width: 90%;
        margin-left: 5%;
        margin-top: 4%;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

    .all-blog-list-inner-container{
        display: grid;
        grid-template-columns: repeat(1,1fr);
     margin-bottom: 3%;
    
    }

    .all-blog-list-card{
        margin: 0%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    }
    
}