@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");

.comments-page-container {
  width: 50%;
  margin-left: 25%;
}

.main-comment-input-container {
  display: flex;
  justify-content: space-between;
}

.main-comment-input {
  width: 100%;
  border: 1px solid rgb(179, 177, 177);
  padding: 1% 0.5% 1% 0.5%;
  border-radius: 4px;
  outline: none;
}

.main-comment-input1 {
  width: 100%;
  border: 1px solid rgb(179, 177, 177);
  padding: 1% 0.5% 1% 0.5%;
  margin-left: 1.5%;
  border-radius: 4px;
  outline: none;
}

.comment-box {
  width: 100%;
  margin-top: 1.5%;
  border: 1px solid rgb(179, 177, 177);
  border-radius: 4px;
  resize: none;
  padding: 1%;
  outline: none;
}

.comment-add-btn {
  border: none;
  width: 100%;
  background-color: #d9281f;
  color: #fff;
  font-family: "Poppins";
  padding: 0.8% 0% 0.8% 0%;
  border-radius: 5px;
  margin-top: 1%;
  margin-bottom: 2%;
  outline: none;
}

.repiles-comments{
    margin-bottom: 3%;
}

.all-comments-container{
    padding: 2%;
    height: 280px;
    overflow:auto;
}

.repiles-comments-name-box{
    display: flex;
}

.repiles-comments-avtar{
    margin-bottom: 0%;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: "Poppins";
    font-size: 90%;
    background-color: rgb(146, 146, 240);
    color:#fff;
}

.repiles-comments-name-email-cont{
    display: flex;
    margin-left: 1%;
    padding-top: 0.6%;
}

.repiles-comments-name{
    font-family: "Poppins";
    font-size: 90%;
    margin-left: 4%;
    text-transform: capitalize;
    width: 100%;
}

.repiles-comments-email{
    font-family: "Poppins";
    font-size: 85%;
    margin-left: 4%;
    text-transform: capitalize;
    width: 100%;
    color: rgb(66, 66, 238);
}

.repiles-comments-inner-container{
    display: flex;
    flex-direction: column;
}

.user-repiles-comments{
    font-family: "Poppins";
    font-size: 90%;
    padding: 0.5%;
    width: max-content;
    border-radius: 2px;
    margin-top: 0.3%;
    margin-bottom: 0%;
    background-color: #f0f0f0;
    padding: 0.3% 2% 0.3% 2%;
    margin-left: 5%;
}

.user-reply-comments-btn{
    border: none;
    background-color: transparent;
    font-size: 80%;
    text-transform: capitalize;
}

.main-reply-container{
    margin-left: 5%;
    display: flex;
    align-items: center;
    margin-bottom: 2.5%;
}

.main-reply-arrow{
    font-size: 75%;
    margin-right: 1%;
}

.second-reply-container{
width: 80%;
margin-bottom: 2%;
}

.second-reply-input-container{
    display: flex;
}

.second-reply-input{
    width: 100%;
    margin-right: 2%;
    outline: none;
    border: 1px solid grey;
    padding: 0.8% 1% 0.8% 1%;
    font-size: 90%;
}

.second-comment-box{
    border: 1px solid grey;
    width: 98%;
    margin-top: 2%;
    padding: 0.8% 1% 0.8% 1%;
}

.second-comment-btn{
    border: none;
    padding: 0.5% 2% 0.5% 2% ;
    background-color: #d9281f;
    font-size: 85%;
    color: #fff;
    border-radius: 2px;
}

.second-repiles-inner-container{
    margin-left: 8%;
}

.repiles-comments-avtar1{
    margin-bottom: 0%;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: "Poppins";
    font-size: 90%;
    background-color: rgb(118, 118, 119);
    color:#fff;
}

@media screen and (max-width: 767px) {
    .comments-page-container {
        width: 90%;
        margin-left: 5%;
      }
}