@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");

.blogs-page {
    padding-top: 1%;
}

.blog-view-page-arrow {
    margin-right: 0.5%;
}

.blog-view-page-back-btn {
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    color: #3f3f3f;
    font-size: 120%;
    margin-left: 6%;
}

.blogview-page-blog-container {
    width: 70%;
    margin-left: 15%;
    margin-top: 1%;
    margin-bottom: 2%;
}

.blogview-blog-thumnNail-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.blogview-blog-thumnNail {
    width: 100%;
    height: 30rem;
}

.blogview-blog-heading {
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-size: 230%;
    line-height: 48px;
    text-align: center;
    color: #1B1C1D;
    font-weight: 600;
    margin-top: 1%;
    width: 95%;
    margin-left: 2.5%;
}

.blogview-blog-date-and-authorname-cont {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 1.5%;
    margin-bottom: 2%;
}

.blogview-blog-date {
    color: #666666;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.blogview-blog-authorname {
    color: #666666;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.blogview-blog-content {
    color: #3f3f3f;
    font-size: 110%;
    width: 95%;
    margin-left: 2.5%;
    padding-top: 1%;
    padding-bottom: 2%;
}

.MuiSnackbarContent-root {
    background-color: red !important;
}

.blog-view-share-like-btn-cont {
    display: flex;
    width: 50%;
    margin-left: 25%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;

}

.thumb-icon {
    font-size: 150%;
    color: rgb(70, 70, 70);
}

.thumb-icon1 {
    font-size: 150%;
    color: rgb(94, 93, 93);
}

.Like-text {
    font-size: 105%;
}

.blog-view-like-btn-wrapper {
    display: flex;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    align-items: center;
}

.share-btn {
    background-color: transparent !important;
    color: rgb(94, 93, 93);
    font-size: 115%;

}

.share-button-icon {
    width: 35px;
    height: 35px;
    margin-left: 12px;
}

.social-copy-btn {
    border: none;
    width: 90%;
    margin-left: 5%;
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;
    padding: 1% 2% 1% 2%;
    background-color: #acacac;
}

@media screen and (max-width: 767px) {
    .blogview-page-blog-container {
        width: 95%;
        margin-left: 5%;
        margin-top: 1%;
        margin-bottom: 2%;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
    }

    .blogview-blog-content img {
        width: 100%;
    }
}