* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.course-view-wrapper{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  width: 80%;
  margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 3%;
  padding-bottom: 2%;
}

.course-view-bgImg {
  width: 100%;
  height: 60vh;
  filter: brightness(40%);

}
.course-view-heading h3 {
  font-family: "Montserrat";
  font-style: normal;
  position: absolute;
  font-weight: 700;
  top: 200px;
  font-size: 250%;
  line-height: 119.5%;
  color: white;
  width: 60%;
  margin-left: 10%;
}

.course-view-heading-container {
  width: 100%;
  height: max-content;
  position: relative;
}

.course-view-learn-heading-container {
  position: relative;
  width: 95%;
  margin-left: 1%;
  padding-top: 2%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.course-view-learn-heading {
  font-size: 150%;
  font-weight: 800;
  font-family: "Montserrat";
  font-style: normal;
  color: #1b1c1d;
  line-height: 120%;
  position: relative;
  z-index: 1;
}

.course-view-heading-hr {
  margin: 0%;
  border: 3px solid #ffd404;
  background-color:#ffd404 ;
  position: absolute;
  width: 24%;
  bottom: 1%;
  z-index: -1;
}

.course-view-content-heading-container {
  position: relative;
  width: 95%;
  margin-left: 1%;
  padding-top: 2%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.course-view-content-heading {
  font-size: 150%;
  font-weight: 800;
  font-family: "Montserrat";
  font-style: normal;
  color: #1b1c1d;
  line-height: 120%;
  position: relative;
  z-index: 1;
}

.course-view-content-heading-hr {
  margin: 0%;
  border: 3px solid #ffd404;
  background-color:#ffd404 ;

  position: absolute;
  width: 22%;
  bottom: 1%;
  z-index: -1;
}
.course-view-learn-container {
  width: 80%;
  margin-left: 10%;
}
.content-view-container {
  width: 80%;
  margin-left: 10%;
  margin-bottom: 5%;
}
.course-view-learn-items {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 100%;
  line-height: 200%;
  color: #1b1c1d;
  display: inline;
}
.course-view-learn-items li {
  list-style: none;
}
.content-view-items {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 100%;
  color: #1b1c1d;
  margin-bottom: 1.5%;
  margin-left: 2.5%;
}
.course-view-learn-check {
  color: #55f265;
  margin-right: 2%;
  display: inline;
}

.view-course-buy-now{
  width: 20%;
  font-family: "Poppins";
  border: none;
  background-color:  rgba(217, 40, 31, 1);
  color: #fff;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 3px;
  margin-left: 10%;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .view-course-buy-now{
    width: 90%;
    font-family: "Poppins";
    border: none;
    background-color:  rgba(217, 40, 31, 1);
    color: #fff;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 3px;
    margin-left: 5%;
  }
  .course-view-wrapper{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
    padding-bottom: 2%;
  }

  .course-view-heading h3 {
    font-family: "Montserrat";
    font-style: normal;
    position: absolute;
    font-weight: 700;
    top: 40%;
    font-size: 150%;
    line-height: 119.5%;
    color: white;
    width: 90%;
    margin-left: 5%;
  }

}
