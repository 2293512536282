@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.about-us-heading-container {
  /* border: 1px solid red; */
  width: 50%;
  margin-left: 25%;
}
.about-us-heading {
  text-align: center;
  margin-bottom: 0%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 250%;
  line-height: 200%;
  color: #1b1c1d;
}
.about-us-heading-container hr {
  margin-bottom: 0%;
}
.about-us-first-row-container {
  /* border:  1px solid red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  margin-left: 15%;
  padding: 2%;
  text-align: justify;

}

.about-us-first-row-column1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-first-row-column2-img {
  width: 70%;
  margin-left: 25%;
}
.about-us-first-row-column1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 95%;
  font-size: 95%;
  line-height: 150%;
  color: #575656;
}

/* Second Row CSS */
.about-us-second-row-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  margin-left: 15%;
  padding: 2%;
  align-items: center;
  justify-content: center;
}
.about-us-second-row-column1-img {
  width: 55%;
  margin-bottom: 2.5%;
  border-radius: 50%;
}
.about-us-second-row-column1-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bolder;
  font-size: 165%;
  line-height: 200%;
  color: #d9281f;
}
.about-us-second-row-column1-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 95%;
  line-height: 150%;
  width: 70%;
  color: #575656;

}

.about-us-second-row-column2-para {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 95%;
  line-height: 160%;
  color: #575656;
}

.about-us-second-row-column2-heading {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 150%;
  line-height: 200%;
  color: #575656;
}

/* 3Rd ROW GRID CSS */
.about-us-third-row-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 75%;
  margin-left: 12.5%;
}
.about-us-third-row-boxes {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 5px;
  border-top: 3px solid #FFD404;
  margin: 4%;
  padding: 6% 0% 6% 0%;
  justify-content: space-around;
}
.about-us-third-row-boxes-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 130%;
  margin-left: 10%;
  color: #d9281f;
}

.about-us-third-row-boxes-para {
  font-family: "Poppins";
  font-style: normal;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-left: 10%;
  font-size: 100%;
  color: #1b1c1d;
}

.about-us-third-row-boxes-para1{
  width: 80%;
  margin-left: 10%;
  font-family: "Poppins";
 font-size: 100%;
 color: #1b1c1d;
}

.about-us-card-3sub-container{
  display: flex;
  align-items: center;
 color: #1b1c1d;

}

.about-us-dot-icon{
  font-size: 50%;
 color: #1b1c1d;
  margin-right: 3%;
}

/* TRAINERS AND MENTORS CSS */
.trainers-mentors-heading-container {
  width: 80%;
  margin-left: 10%;
  margin-top: 3%;
  position: relative;
  margin-bottom: 4%;
}
.trainers-mentors-heading {
  font-family: "Montserrat";
  font-weight: 900;
  position: relative;
  margin-bottom: 0%;
  font-size: 200%;
 color: #1b1c1d;
  z-index: 1;
}
.trainers-mentors-heading-hr {
  border: 3px solid #FFD404;
  width: 13%;
  bottom: -35%;
  position: absolute;
  z-index: -1;
}

/* OUR STORY CSS */
.our-story-heading-container {
  width: 80%;
  margin-left: 10%;
  margin-top: 3%;
  position: relative;
  margin-bottom: 4%;
}
.our-story-heading-hr {
  border: 3px solid #FFD404;
  width: 13%;
  bottom: -35%;
  position: absolute;
  z-index: -1;
}
.our-story-heading {
  font-family: "Montserrat";
  font-weight: 900;
  position: relative;
  margin-bottom: 0%;
  font-size: 200%;
 color: #1b1c1d;
  z-index: 1;
}
.our-story-para {
  width: 70%;
  margin-left: 15%;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 95%;
  line-height: 150%;
  text-align: center;
  color: #1b1c1d;
}

.our-story-container{
  margin-bottom: 3%;
}
.our-story-section {
  width: 80%;
  margin-left: 10%;
}
.our-story-section-left-img {
  width: 100%;
  display: grid;
  justify-content: right;
  align-items: center;
}
.our-story-section-circle {
  display: grid;
  align-items: center;
  justify-content: center;
}
.our-story-section-inner-circle {
  width: 25px;
  height: 25px;
  background: #d9281f;
  border-radius: 50%;
  display: grid;
}
.our-story-section-para-left-wrapper {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 95%;
  line-height: 150%;
  color: #1b1c1d;
  display: flex;
  align-items: center;
  justify-content: left;
}
.our-story-section-para-right-wrapper {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 95%;
  line-height: 150%;

  color: #1b1c1d;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.our-story-section-para {
  margin-bottom: 0%;
}

.our-story-section-left {
  width: 70%;
  margin-left: 15%;
  display: grid;
  grid-template-columns: 47.5% 5% 47.5%;
  padding: 0.5%;
}
.our-story-section-right {
  width: 70%;
  margin-left: 15%;
  display: grid;
  grid-template-columns: 47.5% 5% 47.5%;
  padding: 0.5%;
}
.our-story-section-right-img {
  width: 100%;
  display: grid;
  justify-content: left;
  align-items: center;
}
.about-us-dashed-border {
  width: 0.1%;
  margin-left: 49.95%;
  height: 80px;
  border: 2px dashed #cecece;
  color: red;
}

/* MEDIA QUERIES */

@media screen and (max-width: 767px) {
  .about-us-first-row-column2-img {
    width: 70%;
    margin-left:0%;
  }

  .about-us-first-row-column1 {
    order: 2;
    display: flex;
    text-align: left;
  }
  
  .about-us-first-row-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 70%;
    margin-left: 15%;
    padding: 2%;
  }
  .about-us-second-row-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 70%;
    margin-left: 15%;
    padding: 2%;
    align-items: center;
    justify-content: center;
  }
  .about-us-third-row-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 75%;
    margin-left: 12.5%;
  }
  .our-story-section {
    width: 100%;
  }
  .about-us-dashed-border {
    display: none;
  }
  .our-story-section-left {
    width: 100%;
    margin-left: 0%;
    display: grid;
    grid-template-columns: 30% 5% 65%;
    padding: 0%;
    padding-left: 0%;
    margin-top: 15%;
  }
  .our-story-section-para-left-wrapper {
    width: 100%;
    padding-left: 5%;
  }
  .our-story-section-right {
    width: 70%;
    margin-left: 15%;
    display: grid;
    grid-template-columns: 47.5% 5% 47.5%;
    padding: 0.5%;
  }

  .our-story-section-left-img {
    width: 100%;
    display: grid;
    justify-content: right;
    align-items: center;
  }
  .story-section-left-img {
    width: 90%;
  }

  .our-story-section-left {
    width: 100%;
    margin-left: 0%;
    display: grid;
    grid-template-columns: 30% 5% 65%;
    padding: 0%;
    padding-left: 0%;
    margin-top: 15%;
  }
  .our-story-section-para-right-wrapper {
    width: 100%;
  }
  .our-story-section-right {
    width: 70%;
    margin-left: 15%;
    display: grid;
    grid-template-columns: 47.5% 5% 47.5%;
    padding: 0.5%;
  }

  .our-story-section-right-img {
    width: 100%;
    display: grid;
    justify-content: right;
    align-items: center;
  }
  .story-section-right-img {
    width: 90%;
    margin-left: 10%;
  }

  /* RIGHT SECTION */

  .our-story-section-right {
    width: 100%;
    margin-left: 0%;
    display: grid;
    grid-template-columns: 65% 5% 30%;
    padding: 0%;
    padding-left: 0%;
    margin-top: 15%;
  }
  .our-story-section-para-right-wrapper {
    width: 100%;
    padding-right: 5%;
  }

  .our-story-section-left-img {
    width: 100%;
    display: grid;
    justify-content: left;
    align-items: center;
  }
  .story-section-left-img {
    width: 90%;
  }

  /* DOT CSS */
  .our-story-section-inner-circle {
    width: 15px;
    height: 15px;
    background: #d9281f;
    border-radius: 50%;
    display: grid;
  }

  .our-story-section-para {
    font-size: 90%;
  }

  .our-story-container{
    margin-bottom: 5%;
    padding: 0% 2% 0% 2%;
  }

  .our-story-heading-hr {
    border: 3px solid #FFD404;
    width: 38%;
    bottom: -35%;
    position: absolute;
    z-index: -1;
  }
  

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .our-story-heading-hr{
    margin-top: -3.5%;
  }
  .our-story-para{
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5%;
  }
  .our-story-section-para {
    font-size: 90%;

    margin: 5%;
  }
  .about-us-dashed-border{
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .our-story-section-right{
    width: 90%;
    margin-left: 5%;
  }
  .our-story-section-left{
    width: 90%;
    margin-left: 5%;
  }
  .our-story-section-left-img{
    padding-right: 5%;
  }
  .our-story-section-right-img{
    padding-left: 5%;
  }
  .about-us-first-row-container{
    width: 90%;
    margin-left: 5%;
  }
  .about-us-second-row-container{
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
  }
  .about-us-third-row-container{
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
 
  .about-us-second-row-column2{
    padding-left: 2%;
  }
}
