.chatbot-outer-div {
  position: fixed;
  top: 25%;
  margin-left: 70%;
  z-index: 1;
}
.chatbot-form {
  width: 100%;
  color: black;
}
.chatbot-form h6 {
  color: black;
  font-family: var(--font-family);
}
.chatbot-form-submit-btn {
  background: var(--gradient-bar) !important;
  padding: 2%;
  border-color: var(--gradient-bar);
  width: 150px;
  border-radius: 20px;
}
.rsc-header {
  background: #1675a8 !important;
}
.rsc-input {
  color: black !important;
  font-family: var(--font-family);
}

.rsc-ts-bubble {
  color: black !important;
  background: #f0eff0 !important;
}
.rsc-float-button {
  background: #1675a8 !important;
}
