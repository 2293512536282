@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");

.view-skill-heading-cont{
    position: relative;
    margin-bottom: 2%;
margin-top: 2%;

}
.view-skill-heading{
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-size: 180%;
    margin-top: 1%;
    margin-left: 6%;
    position: relative;
     z-index: 1;
}

.view-skill-heading-hr{
    position: absolute;
    border: 3px solid rgba(255, 212, 4, 1);
    bottom: -30%;
    width: 5%;
    margin-left: 6%;
}

.view-skill-wrapper{
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    border: 1px solid rgba(128, 128, 128, 0.267);
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    width: 70%;
    margin-left: 15%;
    padding: 2%;
    margin-bottom: 2%;
    border-radius: 5px;
}

.view-skill-img{
    width: 60px;
}

.view-skill-title{
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-size: 120%;
    font-weight: 600;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}


.view-skill-desc{
    font-size: 100%;
}

@media screen and (max-width: 767px) {
    .view-skill-wrapper{
        font-family: "Heebo", sans-serif;
        font-family: "Poppins", sans-serif;
        border: 1px solid rgba(128, 128, 128, 0.267);
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
        width: 90%;
        margin-left: 5%;
        padding: 5%;
        margin-bottom: 5%;
        border-radius: 5px;
    }

    .view-skill-title{
        font-family: "Heebo", sans-serif;
        font-family: "Poppins", sans-serif;
        font-size: 120%;
        font-weight: 600;
        margin-top: 3%;
        margin-bottom: 2%;
    }
    
    
    .view-skill-desc{
        font-size: 100%;
    }
    
}