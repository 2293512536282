@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.upcoming-event-page{
    background-color: #F9F9F9;
    padding-bottom: 3%;
}

.upcoming-event-heading-containers{
  position: relative;
  width: 95%;
  margin-left: 4%;
  padding-top: 2%;

}

.upcoming-event-heading{
    font-size: 150%;
    font-weight: 800;
  /* font-family: "Montserrat"; */
  font-family: "Poppins";

  margin-bottom: 0%;
  position: relative;
  z-index: 1;
}

.upcoming-event-heading-line{
    margin: 0%;
    border: 3px solid #FFD404;
    position: absolute;
  width: 14%;
  bottom: 6%;
}

.upcoming-events-cards-container{
    width: 80%;
    margin-left: 10%;
    margin-top: 3%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
}

.upcoming-events-card{
    margin: 6%;
    padding: 3% 3% 3% 5%;
    border-top-right-radius: 60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    background-color: #fff;
}

.upcoming-events-heading{
  font-family: "Poppins";
  font-weight: 600;
  font-size: 100%;
  width: 90%;
  margin-top: 6%;
}

.upcoming-events-date-container{
    display: flex;
  font-family: "Poppins";
  font-size: 90%;
  margin-top: 10%;
}

.upcoming-events-btn{
    border: none;
    background: #D9281F;
    color: #fff;
  font-family: "Poppins";
padding: 1.5% 10% 1.5% 10%;
border-radius: 3px;
margin-top: 4%;
margin-bottom: 6%;
}

@media screen and (max-width: 767px) {
    .upcoming-events-cards-container{
        width: 80%;
        margin-left: 10%;
        margin-top: 3%;
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }

    .upcoming-event-heading-line{
        margin: 0%;
        border: 3px solid #FFD404;
        position: absolute;
      width: 40%;
      bottom: 6%;
    }
    
}