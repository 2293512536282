@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Skills-carousel-container {
  background: #F9F9F9;
  padding: 0.5% 0% 1% 0%;
}

.SkillsComponent-heading {
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 180%;
  position: relative;
  margin-bottom: 0%;
  z-index: 1;
  margin-top: 2%;
}

.SkillsComponent-heading-hr-div {
  width: 95%;
  margin-left: 3%;
  position: relative;

}

.SkillsComponent-heading-hr {
  border: 3px solid rgba(255, 212, 4, 1);
  /* background: rgba(241, 238, 87, 0.972); */
  width: 4%;
  position: absolute;
  bottom: -40%;

}


.swiper-button-prev {
  color: rgb(75, 75, 75) !important;
  height: 35px !important;
  overflow: hidden;
}

.swiper-button-next {
  color: rgb(75, 75, 75) !important;
  height: 35px !important;
  overflow: hidden;
}

.mySwiper {
  /* border: 1px solid red; */
  width: 95%;
  margin-top: 2%;
  margin-bottom: 2%;
  padding-right: 4px !important;
}

.mySwiper-subitem {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgba(128, 128, 128, 0.295);
  background-color: #fff;
  border-top-right-radius: 40px;
}

.mySwiper-subitem-heading {
  font-size: 120%;
  border: 1px solid white;
  width: 80%;
  margin-top: 10%;
  margin-left: 8%;
  /* border: 1px solid red; */
  height: 50px;
}

.mySwiper-subitem-logo {
  width: 50px;
  height: 50px;
  margin-top: 10%;
  margin-left: 8%;
}

.mySwiper-subitem-para {
  width: 80%;
  margin-left: 8%;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  font-size: 90%;
  height: 125px;
  overflow: hidden;
}

.mySwiper-subitem-para1 {
  width: 80%;
  margin-left: 8%;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  font-size: 90%;
  height: 100%;
  overflow: hidden;
}

.mySwiper-subitem-date-container {
  display: flex;
  width: 80%;
  margin-top: 8%;
  margin-left: 8%;

}

.mySwiper-subitem-btns {
  border: none;
  background-color: transparent;
  /* background:rgba(217, 40, 31, 1); */
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  color: rgba(217, 40, 31, 1);
  width: 35%;
  margin-left: 5%;
  padding: 1% 0% 1% 0%;
  margin-top: 3%;
  margin-bottom: 5%;
  cursor: pointer;
}


@media screen and (max-width: 767px) {
  .SkillsComponent-heading {
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 180%;
    position: relative;
    margin-bottom: 0%;
    z-index: 1;
    margin-top: 2%;
  }

  .SkillsComponent-heading-hr {
    border: 3px solid rgba(255, 212, 4, 1);
    background: rgba(241, 238, 87, 0.972);
    width: 10%;
    position: absolute;
    bottom: -40%;

  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .SkillsComponent-heading {
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 180%;
    position: relative;
    margin-bottom: 0%;
    z-index: 1;
    margin-top: 2%;
  }

  .SkillsComponent-heading-hr {
    border: 3px solid rgba(255, 212, 4, 1);
    background: rgba(241, 238, 87, 0.972);
    width: 8%;
    position: absolute;
    bottom: -40%;

  }
}