* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.event-view-heading-container {
  width: 50%;
  margin-left: 25%;
  padding: 2%;
}
.event-view-image {
  display: flex;
  width: 10%;
  margin-left: 45%;
  margin-bottom: 3%;
  
}
.event-view-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 200%;
  line-height: 120%;
  text-align: center;
  color: #1b1c1d;
  margin-bottom: 2%;
}

.event-view-subheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 120%;
  line-height: 110%;
  text-align: center;
  color: #1b1c1d;
}

.event-view-left-section-heading-container {
  position: relative;
}


.event-view-mid-bottom-container {
  width: 70%;
  margin-left: 15%;
  padding: 2% 8%;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 5%;
}
.event-view-middle-section-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.event-view-left-section-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 150%;
  line-height: 150%;
  color: #1b1c1d;
}

.event-view-course-duration-span{
  font-size: 90%;
  font-weight: 500;
  margin-left: 1%;
}

.event-view-left-section-list {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 110%;
  line-height: 150%;
  color: #575656;
}

.event-view-right-section-list {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 110%;
  line-height: 150%;
  color: #575656;
}

.event-view-bottom-section-container {
  margin-top: 5%;
}

.event-view-course-start-date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 105%;
  line-height: 120%;
  color: #d9281f;
}

.event-view-course-duration {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 105%;
  line-height: 120%;
  color: #575656;
}

.event-view-course-address {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 105%;
  line-height: 120%;
  color: #575656;
  margin-bottom: 5%;
}
.event-view-course-register-button {
  padding: 10px 41px;
  background: #d9281f;
  border: none;
  color: white;
  cursor: pointer;
}

.event-view-list-styling{
    font-size: 35%;
    margin-right: 5%;
    align-items: center;
    justify-content: center;
}

/* MEDIA QUERIES */

@media screen and (max-width: 767px) {
  .event-view-heading-container {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 10%;
  }
  .event-view-image {
    width: 20%;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .event-view-middle-section-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 95%;
    margin-left: 2.5%;
    margin-top: 3%;
  }
  .event-view-heading {
    font-size: 120%;
    text-align: center;
  }
  .event-view-subheading {
    font-size: 100%;
  }
  .event-view-left-section-heading {
    font-size: 105%;
    margin-bottom: 5%;
  }
  .event-view-left-section-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 90%;
    line-height: 150%;
    color: #575656;
    margin-bottom: 10%;
  }
  .event-view-right-section-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 90%;
    line-height: 150%;
    color: #575656;
  }
  .event-view-course-start-date {
    font-size: 100%;
  }
  .event-view-course-duration {
    font-size: 100%;
  }
  .event-view-course-address {
    font-size: 100%;
    margin-bottom: 10%;
  }

  .event-view-mid-bottom-container {
    width: 90%;
    margin-left: 5%;
    padding: 2% 8%;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .event-view-heading-container {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 10%;
  }
  .event-view-image {
    width: 10%;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .event-view-middle-section-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 95%;
    margin-left: 2.5%;
    margin-top: 3%;
  }
  .event-view-heading {
    font-size: 120%;
    text-align: center;
  }
  .event-view-subheading {
    font-size: 100%;
  }
  .event-view-left-section-heading {
    font-size: 105%;
    margin-bottom: 5%;
  }
  .event-view-left-section-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 90%;
    line-height: 150%;
    color: #575656;
    margin-bottom: 10%;
  }
  .event-view-right-section-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 90%;
    line-height: 150%;
    color: #575656;
  }
  .event-view-course-start-date {
    font-size: 100%;
  }
  .event-view-course-duration {
    font-size: 100%;
  }
  .event-view-course-address {
    font-size: 100%;
    margin-bottom: 10%;
  }

  .event-view-mid-bottom-container {
    width: 90%;
    margin-left: 5%;
    padding: 2% 8%;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 5%;
  }
}
