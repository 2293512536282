* {
    margin: 0;
    padding: 0;
  }
  .video-gallery-container {
    width: 90%;
    margin-left: 5%;
  }
  .video-gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .video-gallery-inner-wrapper{
      margin: 3%;
  }
  
  .video-iframe{
    width: 280px;
  }

  .video-gallery-videos {
    width: 100%;
    margin-bottom: 0;
  }
  .video-gallery-heading-container {
    padding-top: 3%;
    padding-left: 4%;
    position: relative;
  }
  
  .video-gallery-heading {
    margin: 0%;
    position: relative;
    z-index: 1;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 200%;
    line-height: 300%;
    color: #1b1c1d;
  }
  
  .video-gallery-heading-hr {
    border: 4px solid #ffd404;
    margin: 0%;
    width: 15%;
    position: absolute;
    bottom: 26%;
    left: 4%;
  }
  /* FULL SCREEN CSS */
  .model{
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-color: #000; */
      transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease-in-out;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      overflow: hidden;
      z-index: 999;
  }
  
  .model.open{
      visibility: visible;
      opacity: 1;
      transform: scale(1);
  
  }
  
  .model iframe{
      width: auto;
      max-width: 100%;
      height: auto;
      max-width: 100%;
      display: block;
      line-height: 0;
      box-sizing: border-box;
      padding: 5% 0 5%;
      margin: 0 auto;
  }
  
  .model.open svg{
      position: fixed;
      top: 2%;
      right: 2%;
      width: 2rem;
      height: 2rem;
      padding: 2%;
      background-color: #fff;
      color: red;
      cursor: pointer;
      z-index: 1000;
  }
  
  
  /* MEDIA QUERIES */
  @media screen and (max-width: 767px) {
      .video-gallery-wrapper {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
        }
    }
    
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .video-gallery-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
    }
    