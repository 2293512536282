@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.upcoming-carousel-container {
  width: 90%;
  margin-left: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.upcoming-event-heading-containers {
  margin-bottom: 4%;
  margin-left: 0% !important;
}

.upcoming-mySwiper-subitem {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgba(128, 128, 128, 0.295);
  background-color: #fff;
  border-top-right-radius: 40px;
  padding: 3% 3%;
  padding-bottom: -20% !important;
  height: 100%;
  min-height: 400px !important;
}

.upcoming-mySwiper-subitem-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 10%;
}

.upcoming-mySwiper-subitem-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 120%;
  color: #1b1c1d;
  margin-bottom: 10%;
  /* margin-top: 10%; */
  /* width: 90%; */
  /* margin-left: 5%; */
}

.upcoming-attractions-date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 90%;
  color: #575656;
  margin-bottom: 10%;
}

.upcoming-attractions-more-info-button {
  border: none;
  font-family: "Montserrat";
  text-decoration-line: underline;
  color: #d9281f;
  font-style: normal;
  font-size: 100%;
  font-weight: 700;
  background-color: #fff;
  padding: 2% 10% 2% 2%;
  margin-bottom: 10%;
  cursor: pointer;
  position: absolute;
  bottom: 40px;
}

.upcoming-attractions-register-button {
  padding: 3% 20%;
  cursor: pointer;
  background: #d9281f;
  border: none;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

@media screen and (max-width: 767px) {

  .upcoming-event-heading-line {
    margin: 0%;
    border: 2px solid #FFD404;
    background-color: #FFD404;
    position: absolute;
    width: 50%;
    bottom: 8%;
  }

  .upcoming-event-heading-containers {
    position: relative;
    width: 95%;
    margin-left: 4%;
    padding-top: 2%;
    margin-top: 5%;
    margin-bottom: 6%;

  }

  .upcoming-mySwiper-subitem {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid rgba(128, 128, 128, 0.295);
    background-color: #fff;
    border-top-right-radius: 40px;

  }

  .upcoming-mySwiper-subitem-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-size: 120%;
    color: #1b1c1d;
    margin-bottom: 0.5%;
    margin-top: 10%;
    width: 90%;
    margin-left: 5%;

  }


  .upcoming-attractions-date {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 90%;
    color: #575656;
    padding: 0%;
    margin-left: 5%;
    width: 90%;
    margin-top: 15%;

  }


  .upcoming-attractions-more-info-button {
    font-family: "Montserrat";
    text-decoration-line: underline;
    color: #d9281f;
    font-style: normal;
    font-size: 100%;
    font-weight: 700;
    padding-bottom: 0%;
    width: 50%;
    margin-left: 5%;
    margin-top: 1%;
    text-decoration: none;

  }

  .upcoming-attractions-more-info-button:hover {
    color: #d9281f;
    text-decoration: none;
  }

  .upcoming-attractions-register-button {
    padding: 1% 3%;
    background: #d9281f;
    border: none;
    color: white;
    width: 70%;
    margin-left: 5%;
    margin-top: 5%;
    text-align: center;


  }


  .upcoming-event-heading {
    font-size: 180%;
    font-weight: 800;
    font-family: "Poppins";

    margin-bottom: 0%;
    position: relative;
    z-index: 1;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .upcoming-mySwiper-subitem {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid rgba(128, 128, 128, 0.295);
    background-color: #fff;
    border-top-right-radius: 40px;
  }

  .upcoming-mySwiper-subitem-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-size: 120%;
    color: #1b1c1d;
    margin-bottom: 0.5%;
    margin-top: 5%;
    width: 90%;
    margin-left: 5%;

  }


  .upcoming-attractions-date {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 90%;
    color: #575656;
    padding: 0%;
    margin-left: 5%;
    width: 90%;
    margin-top: 12%;

  }

  .upcoming-event-heading {
    font-size: 180%;
    font-weight: 800;

    font-family: "Poppins";

    margin-bottom: 5%;
    position: relative;
    z-index: 1;
  }



  .upcoming-attractions-more-info-button {
    font-family: "Montserrat";
    text-decoration-line: underline;
    color: #d9281f;
    font-style: normal;
    font-size: 100%;
    font-weight: 700;
    padding-bottom: 0%;
    width: 50%;
    margin-left: 5%;
    margin-top: 2%;
  }

  .upcoming-attractions-register-button {
    padding: 1% 3%;
    background: #d9281f;
    border: none;
    color: white;
    width: 70%;
    margin-left: 5%;
    margin-top: 8%;
    text-align: center;


  }

  .upcoming-event-heading-line {
    margin: 0%;
    border: 2px solid #FFD404;
    background-color: #FFD404;
    position: absolute;
    width: 25%;
    bottom: 8%;
  }
}