@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}


.navbar-wrapper {
  position: absolute;
  top: 0%;
  z-index: 2;
}

.navbar-logo2 {
  width: 9%;
  margin-left: 3%;
}

.navbar-items-container {
  width: 50%;
  margin-left: 10%;
  display: flex;
  justify-content: space-between;
}

.navbar-items2 {
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 105%;
  color: #fff;
  cursor: pointer;

}

.navbar-items2:hover {
  text-decoration: none;
  padding-bottom: 1%;
  transition: all 0.3s ease-in-out;
  color: #ffd404;
  cursor: pointer;
}

.navbar-languages-wrapper {
  display: flex;
  padding-left: 0%;
  align-items: center;
}

.navbar-globe-icon {
  font-size: 110%;
  margin-right: 8%;
  color: #fff;
}

.navbar-language-and-btn-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  width: 30%;
  /* width:max-content; */
  margin-left: 15vw;
}


.navbar2-last-btn {
  border: none;
  /* width: 10vw; */
  width: 100%;
  margin-left: 0%;
  padding: 2% 0% 2% 0%;
  background-color: #d9281f;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  font-size: 105%;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.navbar2-last-btn:hover {
  background-color: #d64f48f6;
}

@keyframes animate {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  0% {
    left: 0;
  }
}

.is-active {
  text-decoration: none;
  padding-bottom: 1%;
  border-bottom: 2px solid #ffd404;
  transition: all 0.3s ease-in-out;
  color: #ffd404;
}

@media screen and (max-width: 767px) {
  .is-active {
    text-decoration: none;
    padding-bottom: 1%;
    border-bottom: none;
    transition: all 0.3s ease-in-out;
    color: #ffd404;
  }

  .navbar-logo {
    width: 26%;
    margin-left: 3%;
  }

  .navbar-items-container {
    width: auto;
    margin-left: 0%;
    margin-top: 3%;
  }

  .navbar-items {
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 110%;
    color: #1b1c1d;
  }

  .navbar-language-and-btn-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: auto;
    margin-left: 0%;
  }



  .navbar-languages-wrapper {
    display: flex;
    align-items: center;
    padding-left: 0%;
    margin-top: 1%;
    margin-bottom: 2%;
  }

  .navbar-globe-icon {
    font-size: 110%;
    margin-right: 4%;
  }


  .navbar-light .navbar-toggler {
    background-color: #bbbaba !important;
    color: #fff !important;
    margin-left: 80%;
    margin-top: -10%;
  }

  .collapse.show {
    background-color: #fff;
    padding-left: 5%;
  }

  .navbar-items2 {
    color: #1b1c1d;
    cursor: pointer;
  }

  .navbar-items2 .navbar-globe-icon {
    margin-right: 4%;
    color: #1b1c1d;
    cursor: pointer;
  }

  .navbar-items2:hover {
    cursor: pointer;
  }

  .navbar-language-and-btn-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: auto;
    margin-left: 0%;
  }

  .navbar-select-language {
    border: none;
    font-weight: 500;
    font-size: 110%;
    color: #1b1c1d;

  }

  .navbar-select-language-option {
    color: #1b1c1d;
  }

  .navbar2-last-btn {
    border: none;
    width: 40%;
    padding: 2% 0% 2% 0%;
    border: 3px solid #1b1c1d;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    font-size: 90%;
    font-weight: 600;
    background-color: transparent;
    color: #1b1c1d;
    border-radius: 3px;
    margin-bottom: 5%;
  }
}


@media screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar-light .navbar-toggler {
    background-color: #bbbaba !important;
    color: #fff !important;
  }

  .collapse.show {
    background-color: #fff;
    padding-left: 5%;
  }

  .navbar-logo {
    width: 18%;
    margin-left: 3%;
  }

  .navbar-items2 {
    color: #1b1c1d;
    cursor: pointer;
  }

  .navbar-globe-icon {
    margin-right: 4%;
    color: #1b1c1d;
  }


  .navbar-language-and-btn-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: auto;
    margin-left: 0%;
  }

  .navbar-select-language {
    border: none;
    font-weight: 500;
    font-size: 110%;
    color: #1b1c1d;

  }

  .navbar-select-language-option {
    color: #1b1c1d;
  }

  .navbar2-last-btn {
    border: none;
    width: 25%;
    padding: 1% 0% 1% 0%;
    border: 1px solid #1b1c1d;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    font-size: 90%;
    font-weight: 600;
    background-color: transparent;
    color: #1b1c1d;
    border-radius: 3px;
    margin-bottom: 5%;
  }

  .navbar-items-container {
    width: auto;
    margin-left: 0%;
    margin-top: 3%;
  }

  .navbar-items {
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 110%;
    color: #1b1c1d;
  }

  .navbar-language-and-btn-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: auto;
    margin-left: 0%;
  }



  .navbar-languages-wrapper {
    display: flex;
    align-items: center;
    padding-left: 0%;
    margin-top: 1%;
    margin-bottom: 2%;
  }

  .navbar-globe-icon {
    font-size: 110%;
    margin-right: 2%;
  }

  .is-active {
    text-decoration: none;
    padding-bottom: 1%;
    border-bottom: none;
    transition: all 0.3s ease-in-out;
    color: #ffd404;
  }

}