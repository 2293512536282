@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.event-page-container {
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  width: 85%;
  margin-left: 7.5%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3% 5%;
  margin-bottom: 10%;
  margin-top: 5%;
}

.upcoming-event-heading {
  margin-left: 5%;
}

.upcoming-event-heading-line {
  margin-left: 5% !important;
}

.event-page-inner-container {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border: 1px solid rgba(128, 128, 128, 0.178);
  margin: 5%;
  border-radius: 3px;
  /* height: 100%; */
  min-height: 300px;
  position: relative;
}

.mySwiper-subitem-logos {
  width: 18%;
  margin-top: 6%;
  margin-left: 5%;
}

.upcoming-mySwiper-subitem-headings {
  font-size: 125%;
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
  font-weight: 600;
}

.upcoming-attractions-dates {
  width: 90%;
  margin-left: 5%;
  font-size: 95%;
  /* height: 20%; */
  /* margin-bottom: 10%; */
}

.upcoming-attractions-more-info-buttons {
  font-size: 100%;
  color: #d9281f;
  width: 90%;
  margin-left: 5%;
  margin-top: 1%;
  text-decoration: none;
  position: absolute;
  bottom: 60px;
}

.upcoming-attractions-more-info-buttons:hover {
  color: #d9281f;
  text-decoration: none;
}

.upcoming-attractions-register-btns {
  border: none;
  background: #d9281f;
  color: #fff;
  padding: 1% 5% 1% 5%;
  border-radius: 3px;
  margin-left: 5%;
  margin-top: 1%;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  /* border: 1px solid black; */
}

.upcoming-attractions-btn-wrapper {
  /* position: relative; */
  /* bottom: 0px; */
}

/* MEDIA QUERIES */

@media screen and (max-width: 767px) {
  .event-page-container {
    margin-top: 10%;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    margin-top: 10%;
  }

  .event-page-inner-container {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 8%;
  }

  .upcoming-attractions-register-btn {
    border: none;
    background: #d9281f;
    color: #fff;
    padding: 1% 5% 1% 5%;
    border-radius: 3px;
    margin-left: 10%;
    margin-bottom: 6%;
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .event-page-container {
    margin-top: 10%;
    width: 80%;
    margin-left: 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5%;
    margin-bottom: 10%;
  }
}