@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");

.image-div-2 {
  height: 100vh;
}


.homepage-carousel-img {
  width: 100%;
  height: 100vh;
}



.homepage-top-box-container {
  background-color: rgba(0, 0, 0, 0.753);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 55% 45%;
}

.homepage-top-box-1 {
  display: flex;
  align-items: flex-end;
}

.homepage-top-box1-sub-box {
  /* border: 1px solid red; */
}

.homepage-heading {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  font-size: 280%;
  color: #fff;
  font-weight: 900;
  /* border: 1px solid #fff; */
  width: 60%;
  margin-left: 20%;
  word-spacing: 4px;
  letter-spacing: 1px;
}

.homepage-heading1 {
  font-family: 'Montserrat', sans-serif;
  /* font-family: "Heebo", sans-serif; */
  /* font-family: "Poppins", sans-serif; */
  font-size: 280%;
  color: #fff;
  font-weight: 900;
  /* border: 1px solid #fff; */
  width: 65%;
  margin-left: 20%;
  word-spacing: 4px;
}


.homepage-para {
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  color: #fff;
  width: 65%;
  font-size: 105%;
  margin-left: 20%;
}

.homepage-top-box-2 {
  /* border: 1px solid #fff; */
  display: flex;
  align-items: flex-end;

}

.homepage-btn {
  border: none;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #d9281f;
  padding: 1.2% 5% 1.2% 5%;
  margin-left: 20%;
  margin-top: 1.5%;
  border-radius: 1px;
  font-size: 105%;
  cursor: pointer;

}

.homepage-img-container {
  width: 45%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 15%;
  margin-left: 20%;
  margin-top: 5%;
}

.homepage-celebrating-img {
  width: 75%;
}

.homepage-count-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-count {
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 180%;
  margin-bottom: 0%;
  text-align: center;
  background: linear-gradient(180deg, #D8B53A 12.38%, #FFEFB6 17.03%, #D79B03 27.9%, #E3C763 33.66%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.homepage-count-para {
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  font-size: 105%;
  text-align: center;
  margin-bottom: 0%;
}

.homepage-top-box-2-img {
  width: 100%;
  height: 80vh;

}

.homepage-main-box-heading {
  margin-left: 8%;
  position: absolute;
  top: 24%;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  /* border: 1px solid red; */
  color: #fff;
  font-weight: 900;
  font-size: 320%;
  word-wrap: break-word;
  width: 40%;
}



.homepage-main-box-para-container {
  position: absolute;
  top: 43%;
  padding-left: 8%;
}

.homepage-main-box-para {
  color: #fff;
  /* border: 1px solid white; */
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  width: 70%;
  word-wrap: break-word;
  margin-bottom: 0%;
}

.homepage-main-box-btn {
  border: none;
  background-color: #d9281f;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  padding: 1.5% 0% 1.5% 0%;
  width: 30%;
  margin-top: 2%;
}


.homepage-main-box-numbers-container {
  /* border: 1px solid white; */
  display: flex;
  margin-top: 2%;
}



.homepage-main-box-numbers {
  /* border: 1px solid green; */
  font-weight: 900;
  font-size: 220%;
  text-align: center;
  /* color: #fff; */
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0%;
  /* background: linear-gradient(180deg, #D8B53A 12.38%, #FFEFB6 17.03%, #D79B03 27.9%, #E3C763 33.66%); */
  background: linear-gradient(180deg, #D8B53A 12.38%, #FFEFB6 17.03%, #D79B03 27.9%, #E3C763 33.66%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

}

.homepage-main-box-numbers-para {
  font-weight: 900;
  font-size: 100%;
  width: 65%;
  margin: auto;
  /* border: 1px solid red; */
  text-align: center;
  margin-bottom: 0%;
  text-align: center;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
}

.homepage-marquee {
  background-color: #d9281f;
  /* margin-bottom: 10%; */
  /* border: 1px solid white; */
}

.homepage-marquee-items {
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0%;
  padding: 1% 0% 1% 0%;
  margin-right: 6%;
  bottom: 20%;
}

.home-page-training-programs-container {
  background-color: #001E22;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 3%;
  align-items: center;
  justify-content: center;
}

.training-programs-illustration-container {
  /* border: 2px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.training-programs-illustration {
  /* border: 2px solid white; */
  width: 90%;
  margin-left: 5%;
}

.training-programs-heading-container {
  /* border: 1px solid white; */
  position: relative;
  margin-bottom: 4%;
}

.training-programs-heading {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 180%;
  position: relative;
  z-index: 1;
}

.training-programs-heading-line {
  color: #D79B03;
  border: 3px solid rgba(255, 212, 4, 1);
  width: 21%;
  position: absolute;
  bottom: -45%;
  z-index: 0;
}

.training-programs-para1 {
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  /* border: 1px solid white; */
  width: 85%;
  margin-left: 20%;
  margin-bottom: 2%;

}

.training-programs-para2 {
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  /* border: 1px solid white; */
  font-size: 110%;
  width: 80%;
  margin-left: 20%;
  margin-bottom: 3%;
}

.training-programs-btn {
  background-color: rgba(217, 40, 31, 1);
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  border: none;
  padding: 1% 4% 1% 4%;
  margin-left: 20%;
  cursor: pointer;

}

.homepage-video-heading {
  margin-left: 4%;
  margin-bottom: 2%;
  margin-top: 2%;
}

.homepage-video-outer-container {
  margin-bottom: 2%;
}

.homepage-video-container {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 90%;
  margin-left: 5%;


}

.homepage-video {
  /* width: 100%; */
  width: 300px;
  height: 210px;
  padding-left: 5%;
  padding-right: 5%;
}

.video-show-more {
  width: 20%;
  margin-left: 85%;
  font-size: 110%;
}

.arrow-right-icon {
  margin-left: 0.7%;
}

/* --------------- */

.homepage-img-heading-cont {
  position: relative;
  margin-bottom: 2%;
}

.homepage-gallery-heading {
  margin-top: 3%;
  margin-bottom: 0%;
  margin-left: -1.5%;
  position: relative;
  z-index: 1;
}

.homepage-img-heading-line {
  border: 3px solid rgba(255, 212, 4, 1);
  position: absolute;
  bottom: -45%;
  width: 13%;
  z-index: -1;

  margin-left: -1.5%;
}


.homepage-video-heading-cont {
  position: relative;
}

.homepage-gallery-heading {
  margin-top: 3%;
  margin-bottom: 0%;
  margin-left: -1.5%;
  position: relative;
  z-index: 1;
}

.homepage-video-heading-line {
  border: 3px solid rgba(255, 212, 4, 1);
  position: absolute;
  bottom: -40%;
  width: 11%;
  z-index: -1;

  margin-left: 4%;
}

.homepage-image-gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.homepage-image-gallery-inner-wrapper {
  margin: 3%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.homepage-image-gallery-images {
  width: 100%;
  height: 100%;
}

.image-see-more-cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  color: rgb(86, 86, 250);
  font-size: 115%;
  align-items: center;
  padding-right: 3%;
}

.home-page-story-img {
  width: 100%;
}

@media screen and (max-width: 767px) {

  .homepage-video-heading-cont {
    position: relative;
    margin-bottom: 8%;
    margin-top: 5%;
  }

  .homepage-video-heading-line {
    border: 3px solid rgba(255, 212, 4, 1);
    background-color: rgba(255, 212, 4, 1);
    position: absolute;
    bottom: -40%;
    width: 40%;
    z-index: -1;

    margin-left: 4%;
  }

  .homepage-img-heading-cont {
    position: relative;
    margin-top: 5%;
    margin-bottom: 8%;
  }

  .homepage-img-heading-line {
    border: 2px solid rgba(255, 212, 4, 1);
    background-color: rgba(255, 212, 4, 1);
    position: absolute;
    bottom: -45%;
    width: 45%;
    z-index: -1;

    margin-left: -1.5%;
  }


  .homepage-image-gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .video-show-more {
    width: 20%;
    margin-left: 8%;
    font-size: 110%;
  }

  .homepage-video-heading {
    margin-left: 5%;
    margin-bottom: 5%;
    margin-top: 2%;
  }


  .homepage-video {
    width: 100%;
    /* width: 300px; */
    height: 210px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 4%;
  }

  .homepage-video-container {
    /* display: flex; */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    margin-left: 5%;


  }

  .homepage-marquee {
    background-color: #d9281f;
  }

  .homepage-marquee-items {
    color: #fff;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0%;
    padding: 1% 0% 1% 0%;
    margin-right: 20%;
  }


  .image-div-2 {
    height: 120vh;
  }

  .homepage-carousel-img {
    width: 100%;
    height: 120vh;
  }



  .homepage-top-box-container {
    background-color: rgba(0, 0, 0, 0.753);
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 120vh;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }


  .homepage-top-box-1 {
    display: flex;
    align-items: center;
    margin-top: 25%;
  }



  .homepage-heading {
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-size: 180%;
    color: #fff;
    font-weight: 900;
    width: 95%;
    margin-left: 2.5%;
    word-spacing: 4px;
    letter-spacing: 1px;
  }

  .homepage-heading1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 180%;
    color: #fff;
    font-weight: 900;
    width: 95%;
    margin-left: 2.5%;
    word-spacing: 4px;
  }


  .homepage-para {
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    color: #fff;
    width: 95%;
    font-size: 105%;
    margin-left: 2.5%;
  }

  .homepage-top-box-2 {
    display: flex;
    align-items: flex-end;

  }

  .homepage-btn {
    margin-left: 2.5%;
    cursor: pointer;
  }


  .homepage-img-container {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1%;
    margin-left: 2.5%;
    margin-top: 5%;
  }

  .homepage-celebrating-img {
    width: 65%;
  }

  .homepage-top-box-2 {
    overflow: hidden;
  }

  .homepage-top-box-2-img {

    width: 100%;
    height: 100%;


  }



  .home-page-training-programs-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
  }

  .training-programs-heading {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    position: relative;
    z-index: 1;
    font-size: 160%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 8%;
  }

  .training-programs-heading-line {
    color: #D79B03;
    border: 2px solid rgba(255, 212, 4, 1);
    background-color: rgba(255, 212, 4, 1);
    width: 57%;
    position: absolute;
    bottom: -50%;
    z-index: 0;
    margin-left: 5%;

  }

  .training-programs-para1 {
    width: 95%;
    margin-left: 5%;
    text-align: left;
  }

  .training-programs-para2 {
    font-size: 110%;
    width: 95%;
    margin-left: 5%;
    text-align: left;
    margin-top: 2%;
  }

  .training-programs-btn {
    border: none;
    margin-left: 5%;
    width: 50%;
    margin-top: 2%;
    margin-bottom: 5%;
    cursor: pointer;

  }

  .training-programs-illustration {
    width: 100%;
    margin-left: 0%;
    margin-bottom: 5%;
  }



}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  .homepage-image-gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .homepage-video {
    width: 100%;
    height: 210px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 4%;
  }


  .homepage-video-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    margin-left: 5%;


  }

  .homepage-top-box-container {
    background-color: rgba(0, 0, 0, 0.753);
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .homepage-top-box-1 {
    display: flex;
    align-items: flex-end;
    padding-top: 15%;
  }


  .homepage-top-box-2 {
    display: flex;
    align-items: flex-start;
    overflow: hidden;
  }

  .homepage-celebrating-img {
    width: 35%;
    margin-right: 10%;
  }



  .homepage-top-box-2-img {

    width: 100%;
    height: 60vh;


  }




  .homepage-img-container {
    width: 50%;
    display: flex;
    margin-bottom: 1%;
    margin-left: 20%;
    margin-top: 3%;
  }


}