@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}



.navbar-logo-container {
  width: 40%;
  display: flex;
}

.navbar-logo {
  display: block;
  height: 60px !important;
  width: 100px !important;
  width: 9%;
  margin-left: 40%;
  cursor: pointer;

}



.navbar-items-container1 {


  width: 40%;
  margin-left: 12% !important;
  display: flex;
  justify-content: space-between;

}

.navbar-items {
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  color: #1b1c1d;
  font-weight: 600;
  font-size: 105%;
  cursor: pointer;

}

.navbar-items:hover {
  text-decoration: none;
  padding-bottom: 1%;
  color: #ffd404;
}

.navbar-languages-wrapper1 {
  display: flex;
  align-items: center;
  padding-left: 20%;
  margin-left: 50%;
}

.navbar-globe-icon2 {
  font-size: 110%;
  margin-right: 8%;
  color: #1b1c1d;
}

.navbar-language-and-btn-container1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  width: 50%;
}

.navbar-select-language1 {
  border: none;
  background-color: transparent;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 100%;
  /* margin-left: 10%; */

}

.navbar-last-btn {
  border: none;
  width: 60%;
  margin-left: 20%;
  padding: 2% 0% 2% 0%;
  background-color: #d9281f;
  font-family: "Heebo", sans-serif;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  font-size: 105%;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.navbar-last-btn:hover {
  background-color: #d64f48f6;
}

@media screen and (max-width: 767px) {
  .navbar-language-and-btn-container1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: 50%;
    margin-left: 0%;
  }

  .is-active {
    text-decoration: none;
    padding-bottom: 1%;
    border-bottom: none;
    transition: all 0.3s ease-in-out;
    color: #ffd404;
  }

  /* .navbar-logo {
    width: 26%;
    margin-left: 3%;
  } */

  .navbar-last-btn {
    border: none;
    width: 100%;
    margin-left: 0%;
    padding: 2% 0% 2% 0%;
    background-color: #d9281f;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    font-size: 105%;
    font-weight: 500;
    color: #fff;
    border-radius: 3px;
    margin-top: 5%;
  }

  .navbar-languages-wrapper1 {
    display: flex;
    align-items: center;
    padding-left: 2%;
    margin-top: 5%;
  }
}