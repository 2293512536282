@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Poppins&display=swap");


* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.courses-list-top-heading {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    padding-top: 2%;
}

.courses-list-hr-tag {
    width: 50%;
    margin-left: 25%;
    border-top: 1px solid grey;
}

.courses-list-cards-container {
    width: 90%;
    margin-left: 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 1% 0% 1% 0%;
}

.courses-list-card-wrapper {
    margin: 3.5%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: #fff;
    border: 1px solid rgba(128, 128, 128, 0.26);
    /* border: 1px solid red;    */
    min-height: 400px;
    position: relative;
    /* height: 100%; */

}

.courses-card-heading {
    width: 90%;
    margin-left: 5%;
    font-family: 'Montserrat', sans-serif;
    font-size: 90%;
    margin-top: 3%;
    font-weight: 600;
    /* margin-bottom: 40%; */
}

.courses-card-description {
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-size: 90%;
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.courses-list-card-img {
    width: 100%;
}

.courses-list-cards-btn-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 5%;
    /* bottom: 20px; */
    bottom: 0;
    left: 0;
    /* position: relative; */
    /* bottom: 0; */
    /* margin-bottom: 100%; */
    /* margin-top: 25%; */
}

.courses-list-card-btn1 {
    color: #D9281F;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    border: 2px solid #D9281F;
    padding: 1.5% 10% 1.5% 10%;
    /* margin-bottom: 3%; */
    background-color: #fff;
    text-align: center;
    margin-top: 5%;
    position: absolute;
    bottom: 60px;
}

.courses-list-card-btn1:hover {
    color: #D9281F;
    text-decoration: none;
}

.courses-list-card-btn2 {
    border: none;
    background-color: #D9281F;
    color: #fff;
    font-family: "Heebo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    padding: 1.5% 17% 1.5% 17%;
    /* margin-bottom: 3%; */
    text-align: center;
    position: absolute;
    bottom: 20px;
}

.courses-list-card-btn2:hover {
    color: #fff;
    text-decoration: none;
}


@media screen and (max-width: 767px) {
    .courses-list-cards-container {
        width: 95%;
        margin-left: 2.5%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 1% 0% 1% 0%;
    }
}