* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Footer-wrapper {
  background: #1b1c1d;
  color: #fff;
}

.Footer-innner-wrapper {
  display: grid;
  grid-template-columns: 60% 40%;
}

.Footer-container {
  width: 96%;
  margin-left: 4%;
  padding-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.Footer-columns {
  display: flex;
  justify-content: center;
}

.Footer-column-items {
  list-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.Footer-column-items a {
  text-decoration: none;
  color: rgba(221, 216, 216, 0.532);
}

.Footer-column-items .Footer-column-title {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 28px !important;
  color: #ffffff;
  opacity: 1;
}

.Footer-subscribe-box {
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Footer-subscribe-innerbox1 {
  display: grid;
  grid-template-columns: 85% 15%;
  border-top-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.Footer-subscribe-input-box {
  padding: 1.5%;
}

.Footer-arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
}

.Footer-arrow {
  display: inline;
  background-color: #d9281f;
  color: white;
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.Footer-subscribe-para {
  font-family: "Poppins";
  margin-top: 2%;
  font-size: 90%;
  margin-bottom: 0%;
}

.Footer-horizontal-rule {
  width: 96%;
  display: grid;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.487);
  margin-left: 4%;
}

.footer-bottom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3%;
  width: 96%;
  margin-left: 4%;
}

.Footer-bottom {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  margin-left: 5%;
  /* border: 1px solid white; */
}

.Footer-bottom-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 80%;
  line-height: 21px;
  color: #ffffff;
  /* margin: 0% 2% 0% 2%; */
}

.flex-bottom-container {
  margin-left: 9%;
}

.scrobits-link {
  color: gray;
}

.Footer-fontawesome {
  width: 30%;
  display: flex;
  justify-content: space-between;
  margin-left: 10%;
}

.fontawesome-style {
  color: #fff;
  margin-right: 4%;
  font-size: 150%;
}

footer .fontawesome-style:hover {
  transition: all 1s;
  animation: rotatefont 1s linear alternate-reverse;
  color: #fff;
}

@keyframes rotatefont {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1.2);
  }
}


.footer-section-2 {
  margin: 5% 8% 5% 8%;
  padding: 8%;
  background-color: #6c6e7021;
}

.footer-section-2-form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.footer-section-2-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #8d8c8c;
  margin-bottom: 5%;
  padding: 0% 2% 0.5% 2%;
  font-family: "Poppins";
  outline: none;
  caret-color: #fff;
  color: #fff;
}

.footer-section-2-btn {
  border: none;
  font-family: "Poppins";
  padding: 1% 0% 1% 0%;
  border-radius: 3px;
  color: #fff;
  font-size: 90%;
  background-color: #d9281f;
  cursor: pointer;
}

.footer-section-2-heading {
  font-size: 110%;
  font-family: "Poppins";
  word-break: break-all;
  margin-bottom: 5%;
}

.copyright-link {
  text-decoration: none;
  color: white;
  font-family: "Poppins";
  font-size: 100%;
}

@media screen and (max-width: 767px) {

  .Footer-innner-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .Footer-container {
    width: 70%;
    margin-left: 15%;
    padding-top: 5rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .Footer-columns {
    display: flex;
    flex-direction: column;
    align-items: left;
    background: #1b1c1d;
  }

  .Footer-subscribe-box {
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0% 5% 0%;
    margin-top: 3%;
  }

  .footer-bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3%;
    width: 100%;
  }

  .Footer-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Footer-fontawesome {
    width: 35%;
    margin-top: 4%;
    display: flex;
    justify-content: space-between;
    margin-left: 0%;
  }
}